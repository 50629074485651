<template>
  <div>
    <vs-table search stripe border description :sst="true" :data="tableData" :max-items="table.length"
      :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              ">
            <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button size="small" color="success" type="border" @click="handleExport('excel')">Excel</vs-button>
        </div>
      </template>
      <template slot="thead">
        <!-- <vs-th></vs-th> -->
        <vs-th sort-key="sales_office">Sales Office</vs-th>
        <vs-th sort-key="sales_office_desc">Sales Office Desc</vs-th>
        <vs-th sort-key="sold_to_party">Sold To Party</vs-th>
        <vs-th sort-key="sold_to_party_name">Sold To Party Name</vs-th>
        <vs-th sort-key="dist_channel">Dist Channel</vs-th>
        <vs-th sort-key="order_type">Order Type</vs-th>
        <vs-th sort-key="po_number">PO Number</vs-th>
        <vs-th sort-key="po_doc_date">PO Doc Date</vs-th>
        <vs-th sort-key="so_number">SO Number</vs-th>
        <vs-th sort-key="so_doc_date">SO Doc Date</vs-th>
        <vs-th sort-key="so_creation_date">SO Creation Date</vs-th>
        <vs-th sort-key="so_gsv_amt">SO GSV Amt</vs-th>
        <vs-th sort-key="so_dist_amt">SO Dist Amt</vs-th>
        <vs-th sort-key="so_dpp_amt">SO DPP Amt</vs-th>
        <vs-th sort-key="do_number">DO Number</vs-th>
        <vs-th sort-key="do_creation_date">DO Creation Date</vs-th>
        <vs-th sort-key="do_creation_date">DO DPP Amt</vs-th>
        <vs-th sort-key="gi_number">GI Number</vs-th>
        <vs-th sort-key="actual_gi_date">Actual GI Date</vs-th>
        <vs-th sort-key="posting_date">Posting Date</vs-th>
        <vs-th sort-key="di_dpp_amt">GI DPP Amt</vs-th>
        <vs-th sort-key="billing_number">Billing Number</vs-th>
        <vs-th sort-key="billing_date">Billing Date</vs-th>
        <vs-th sort-key="billing_gsv_amt">Billing GSV Amt</vs-th>
        <vs-th sort-key="billing_dist_amt">Billing Dist Amt</vs-th>
        <vs-th sort-key="billing_dpp_amt">Billing DPP Amt</vs-th>
        <vs-th sort-key="update_by">Update By</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].sales_office">
            {{ data[indextr].sales_office }}
          </vs-td>
          <vs-td :data="data[indextr].sales_office_desc">
            {{ data[indextr].sales_office_desc }}
          </vs-td>
          <vs-td :data="data[indextr].sold_to_party">
            {{ data[indextr].sold_to_party }}
          </vs-td>
          <vs-td :data="data[indextr].sold_to_party_name">
            {{ data[indextr].sold_to_party_name }}
          </vs-td>
          <vs-td :data="data[indextr].dist_channel">
            {{ data[indextr].dist_channel }}
          </vs-td>
          <vs-td :data="data[indextr].order_type">
            {{ data[indextr].order_type }}
          </vs-td>
          <vs-td :data="data[indextr].po_number">
            {{ data[indextr].po_number }}
          </vs-td>
          <vs-td :data="data[indextr].po_doc_date">
            {{ dateFormat(data[indextr].po_doc_date) }}
          </vs-td>
          <vs-td :data="data[indextr].so_number">
            {{ data[indextr].so_number }}
          </vs-td>
          <vs-td :data="data[indextr].so_doc_date">
            {{ dateFormat(data[indextr].so_doc_date) }}
          </vs-td>
          <vs-td :data="data[indextr].so_creation_date">
            {{ dateFormat(data[indextr].so_creation_date) }}
          </vs-td>
          <vs-td :data="data[indextr].so_gsv_amt">
            {{ data[indextr].so_gsv_amt }}
          </vs-td>
          <vs-td :data="data[indextr].so_dist_amt">
            {{ data[indextr].so_dist_amt }}
          </vs-td>
          <vs-td :data="data[indextr].so_dpp_amt">
            {{ data[indextr].so_dpp_amt }}
          </vs-td>
          <vs-td :data="data[indextr].gi_number">
            {{ data[indextr].gi_number }}
          </vs-td>
          <vs-td :data="data[indextr].actual_gi_date">
            {{ dateFormat(data[indextr].actual_gi_date) }}
          </vs-td>
          <vs-td :data="data[indextr].posting_date">
            {{ dateFormat(data[indextr].posting_date) }}
          </vs-td>
          <vs-td :data="data[indextr].di_dpp_amt">
            {{ data[indextr].di_dpp_amt }}
          </vs-td>
          <vs-td :data="data[indextr].billing_number">
            {{ data[indextr].billing_number }}
          </vs-td>
          <vs-td :data="data[indextr].billing_date">
            {{ dateFormat(data[indextr].billing_date) }}
          </vs-td>
          <vs-td :data="data[indextr].billing_gst_amt">
            {{ data[indextr].billing_gst_amt }}
          </vs-td>
          <vs-td :data="data[indextr].billing_dist_amt">
            {{ data[indextr].billing_dist_amt }}
          </vs-td>
          <vs-td :data="data[indextr].billing_dpp_amt">
            {{ data[indextr].billing_dpp_amt }}
          </vs-td>
          <vs-td :data="data[indextr].update_by">
            {{ data[indextr].update_by }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination style="padding-top: 5px" :total="total" v-model="setPage" />
  </div>
</template>
<script>
import moment from "moment";
import { mapState, mapActions } from "vuex/dist/vuex.common.js";
export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    startPostingDate: {
      type: Date,
    },
    endPostingDate: {
      type: Date,
    },
    startSoDate: {
      type: Date,
    },
    endSoDate: {
      type: Date,
    },
    territoryIDs: {
      type: Array,
    },
    distributionChannelIDs: {
      type: Array,
    },
    salesIDs: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({ getSODOGIBILLReport: 'reportSODOGIBILL/getSODOGIBILLReport', generateSODOGIBILLReport: 'reportSODOGIBILL/generateSODOGIBILLReport' }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleExport() {
      this.$vs.loading();
      this.generateSODOGIBILLReport(
        {
          length: this.table.length,
          page: this.table.page,
          search: this.table.search == '' ? undefined : this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          territory:
            this.territoryIDs.length == 0
              ? undefined
              : this.territoryIDs.join(','),
          distribution_channel:
            this.distributionChannelIDs.length == 0
              ? undefined
              : this.distributionChannelIDs.join(','),
          sales:
            this.salesIDs.length == 0
              ? undefined
              : this.salesIDs.join(','),
          start_so_date:
            this.startSoDate == null
              ? undefined
              : moment(this.startSoDate).format('YYYY-MM-DD'),
          end_so_date:
            this.endSoDate == null
              ? undefined
              : moment(this.endSoDate).format('YYYY-MM-DD'),
          start_posting_date:
            this.startPostingDate == null
              ? undefined
              : moment(this.startPostingDate).format('YYYY-MM-DD'),
          end_posting_date:
            this.endPostingDate == null
              ? undefined
              : moment(this.endPostingDate).format('YYYY-MM-DD'),
        }
      ).then(() => {
        this.$vs.loading.close();
      })
    },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.getSODOGIBILLReport({
          length: this.table.length,
          page: this.table.page,
          search: this.table.search == '' ? undefined : this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          territory:
            this.territoryIDs.length == 0
              ? undefined
              : this.territoryIDs.join(','),
          distribution_channel:
            this.distributionChannelIDs.length == 0
              ? undefined
              : this.distributionChannelIDs.join(','),
          sales:
            this.salesIDs.length == 0
              ? undefined
              : this.salesIDs.join(','),
          start_so_date:
            this.startSoDate == null
              ? undefined
              : moment(this.startSoDate).format('YYYY-MM-DD'),
          end_so_date:
            this.endSoDate == null
              ? undefined
              : moment(this.endSoDate).format('YYYY-MM-DD'),
          start_posting_date:
            this.startPostingDate == null
              ? undefined
              : moment(this.startPostingDate).format('YYYY-MM-DD'),
          end_posting_date:
            this.endPostingDate == null
              ? undefined
              : moment(this.endPostingDate).format('YYYY-MM-DD'),
        }).then(() => {
          this.$vs.loading.close();
        });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    draw() {
      this.getData();
    },
    dataList(val) {
      this.table.start = ((this.table.page - 1) * this.table.length) + 1
      this.table.end = ((this.table.page - 1) * this.table.length) + val.length
    },
    generateReport(val) {
      if (val && val.status === 'OK') {
        this.$vs.notify({
          color: "success",
          title: "Processing",
          text: val.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: val.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    totalRecord(val) {
      this.table.total = val
    },
  },
  computed: {
    ...mapState({
      tableData: (state) => state.reportSODOGIBILL.tableData,
      total: (state) => state.reportSODOGIBILL.total,
      totalRecord: (state) => state.reportSODOGIBILL.total_record,
      generateReport: (state) => state.reportSODOGIBILL.generateReport,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>